import { Pinia } from 'pinia'
import { useAppStore } from '~/store/app'

export default defineNuxtPlugin((nuxtApp) => {
  const { $device, $config } = nuxtApp as Record<string, any>
  const store: any = useAppStore(nuxtApp.$pinia as Pinia)
  const route = useRoute()

  if ($device.isDesktop && route.name !== 'maintenance' && route.path !== '/game-mini') {
    const windowObj = window as any

    windowObj.__lc = windowObj.__lc || {}
    windowObj.__lc.license = $config.LIVE_CHAT
    windowObj.LC_API = windowObj.LC_API || {}

    windowObj.LC_API.on_after_load = () => {
      store.liveChatLoaded = true
      if (window.innerWidth <= 992) {
        const interval = setInterval(() => {
          if (windowObj.LC_API.chat_window_minimized()) {
            windowObj.LC_API.hide_chat_window()
            clearInterval(interval)
          }
        }, 100)
      }
    }

    const lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.defer = true
    lc.src = `${document.location.protocol}//cdn.livechatinc.com/tracking.js`
    document.getElementsByTagName('head')[0].appendChild(lc)
  }

  const openLiveChat = () => {
    const openNewTab = window.open('about:blank', '_blank')
    if (openNewTab) {
      openNewTab.location.href = `https://secure.livechatinc.com/licence/${$config.LIVE_CHAT}/v2/open_chat.cgi`
    }
  }

  return {
    provide: {
      openLiveChat
    }
  }
})
