import { UA } from '~/helper/user-agent'

const DEFAULT_USER_AGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.181 Safari/537.36'

export default defineNuxtPlugin(({ ssrContext }) => {
  let userAgent = DEFAULT_USER_AGENT

  if (ssrContext?.event?.node?.req?.headers?.['user-agent']) {
    userAgent = ssrContext.event.node.req.headers['user-agent']
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent
  }

  return {
    provide: {
      ua: new UA(userAgent)
    }
  }
})
