import validate from "D:/Vue/ps15one-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45layout_45global from "D:/Vue/ps15one-web/middleware/setLayout.global.ts";
export const globalMiddleware = [
  validate,
  set_45layout_45global
]
export const namedMiddleware = {
  auth: () => import("D:/Vue/ps15one-web/middleware/auth.ts"),
  "page-desktop": () => import("D:/Vue/ps15one-web/middleware/pageDesktop.ts"),
  "page-mobile": () => import("D:/Vue/ps15one-web/middleware/pageMobile.ts"),
  "un-auth": () => import("D:/Vue/ps15one-web/middleware/unAuth.ts")
}