export const PAGE = {
  HOME: '/',
  GAMES: '/games',
  GAMES_LOTTERY: '/games?type=lottery&provider=all',
  ACCOUNT: '/account',
  ACCOUNT_DEPOSIT: '/account/deposit',
  ACCOUNT_DEPOSIT_BANK: '/account/deposit/bank',
  ACCOUNT_DEPOSIT_DA: '/account/deposit/bank?type=codepay',
  ACCOUNT_DEPOSIT_CODEPAY2: '/account/deposit/bank?type=codepay2',
  ACCOUNT_DEPOSIT_MOMO: '/account/deposit/momo',
  ACCOUNT_DEPOSIT_CARD: '/account/deposit/card',
  ACCOUNT_DEPOSIT_CRYPTO: '/account/deposit/crypto',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_PROFIT_TODAY: '/account/profit-today',
  ACCOUNT_BANK: '/account/bank',
  ACCOUNT_HISTORY_TRANSACTION: '/account/history/transaction',
  ACCOUNT_HISTORY_BETTING: '/account/history/bet',
  SPORTS_SCHEDULE: '/the-thao/lich-thi-dau',
  PROMOTION: '/khuyen-mai',
  REWARD: '/reward',
  PROMOTION_CATEGORY: '/khuyen-mai/the-loai',
  ACCOUNT_WITHDRAW: '/account/withdraw',
  ACCOUNT_WITHDRAW_BANK: '/account/withdraw/bank',
  CASINO: '/livecasino',
  SPORTS: '/sports',
  KSPORT: '/sports/ksports',
  CSPORT: '/sports/csports',
  SSPORT: '/sports/ssports',
  PSPORT: '/sports/psports',
  MSPORT: '/sports/msports',
  ESPORT: '/sports/esports',
  TSPORT: '/sports/tsports',
  SUPPORT: '/support',
  VIRTUAL_SPORTS: '/virtual-sports',
  ACCOUNT_REWARD: '/account/bonus',
  ACCOUNT_P2P: '/account/trading-p2p',
  ABOUT_US: '/about-us',
  SPORTS_SCHEDULE_MATCH: '/sports/schedule/match',
  MAINTAIN: '/maintain',
  PAGE404: '/404',
  MAINTENENCE: '/maintenence',
  LODE: '/lode',
  NEWS: '/tin-tuc',
  NEWS_CATEGORY: '/tin-tuc/the-loai',
  QUESTION: '/question',
  INSTRUCTION: '/instruction',
  ACCOUNT_HISTORY_BET: '/account/history/bet',
  LOBBY: '/game',

  CONDITIONS: '/gioi-thieu/dieu-khoan',
  GUIDE: '/huong-dan/nap-tien',
  GUIDE_REGISTER: '/huong-dan-dang-ky',
  NHAN_DINH_BONG_DA: '/nhan-dinh-bong-da',
  SOI_KEO: '/soi-keo',
  KEO_BONG_RO: '/keo-bong-ro',
  LO_DE_ONLINE: '/lo-de-online',
  NO_HU: '/no-hu',
  BAN_CA: '/ban-ca',
  TAI_XIU: '/tai-xiu',
  TIP_BONG_DA: '/tip-bong-da'
}
