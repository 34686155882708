export default defineNuxtRouteMiddleware((to, from) => {
  const { $device } = useNuxtApp()

  if (to.name === 'whitelist' || to.name === 'verify-mail' || to.name === 'maintenance') {
    setPageLayout('empty')
    return
  }

  const layout = $device.isMobileOrTablet ? 'mobile' : 'default'
  setPageLayout(layout)
})
