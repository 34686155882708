import { default as bankedGDWmxW1xMeta } from "D:/Vue/ps15one-web/pages/account/bank.vue?macro=true";
import { default as bankBpUIC7NqqWMeta } from "D:/Vue/ps15one-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardwSCPmIlML6Meta } from "D:/Vue/ps15one-web/pages/account/deposit/card.vue?macro=true";
import { default as codepay8CYNmbInJSMeta } from "D:/Vue/ps15one-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2tXdOeqYFwFMeta } from "D:/Vue/ps15one-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptoT22mli5di7Meta } from "D:/Vue/ps15one-web/pages/account/deposit/crypto.vue?macro=true";
import { default as momor7GSUlCxGbMeta } from "D:/Vue/ps15one-web/pages/account/deposit/momo.vue?macro=true";
import { default as betkU6xKVBQldMeta } from "D:/Vue/ps15one-web/pages/account/history/bet.vue?macro=true";
import { default as transactionKev395Q6NcMeta } from "D:/Vue/ps15one-web/pages/account/history/transaction.vue?macro=true";
import { default as historyHZ7SU0RRZvMeta } from "D:/Vue/ps15one-web/pages/account/history.vue?macro=true";
import { default as profileQ4iRJGOUA0Meta } from "D:/Vue/ps15one-web/pages/account/profile.vue?macro=true";
import { default as bankUAOsx0DNKLMeta } from "D:/Vue/ps15one-web/pages/account/withdraw/bank.vue?macro=true";
import { default as card3ZKifv2DkFMeta } from "D:/Vue/ps15one-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoAdyaHGWp6cMeta } from "D:/Vue/ps15one-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountXb9Q0qEyE6Meta } from "D:/Vue/ps15one-web/pages/account.vue?macro=true";
import { default as _91alias_93iLjCmMdub6Meta } from "D:/Vue/ps15one-web/pages/cau-hoi-thuong-gap/[alias].vue?macro=true";
import { default as cau_45hoi_45thuong_45gaph4vZlmfIzWMeta } from "D:/Vue/ps15one-web/pages/cau-hoi-thuong-gap.vue?macro=true";
import { default as base_45buttontISnuJm7WtMeta } from "D:/Vue/ps15one-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downPNFpZN7bI6Meta } from "D:/Vue/ps15one-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiokhGSgKimWcMeta } from "D:/Vue/ps15one-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgCeLZVrOXvFMeta } from "D:/Vue/ps15one-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputVUY24jUGX1Meta } from "D:/Vue/ps15one-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingB4rbUpSqeaMeta } from "D:/Vue/ps15one-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalPsEFkK72POMeta } from "D:/Vue/ps15one-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputwcihpHKBx9Meta } from "D:/Vue/ps15one-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationBclfZx6TaHMeta } from "D:/Vue/ps15one-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45banky1OlZ8s7PpMeta } from "D:/Vue/ps15one-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemyYepjAmzzkMeta } from "D:/Vue/ps15one-web/pages/dev/game-item.vue?macro=true";
import { default as indexxt7GbJsKldMeta } from "D:/Vue/ps15one-web/pages/dev/index.vue?macro=true";
import { default as _91type_939QdrAfYNe1Meta } from "D:/Vue/ps15one-web/pages/game/[type].vue?macro=true";
import { default as gameVjTWUxCFpsMeta } from "D:/Vue/ps15one-web/pages/game.vue?macro=true";
import { default as _91alias_93DdJI3r7bGkMeta } from "D:/Vue/ps15one-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieu5wVh74jK2KMeta } from "D:/Vue/ps15one-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93CcHuuyeHOTMeta } from "D:/Vue/ps15one-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dandk6vPvminRMeta } from "D:/Vue/ps15one-web/pages/huong-dan.vue?macro=true";
import { default as indexEBVzRFFC3yMeta } from "D:/Vue/ps15one-web/pages/index.vue?macro=true";
import { default as _91id_93WCk3ZNxxNXMeta } from "D:/Vue/ps15one-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexu2smPBGRiVMeta } from "D:/Vue/ps15one-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93IMISfzzAWIMeta } from "D:/Vue/ps15one-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipv1C2GuEiSdMeta } from "D:/Vue/ps15one-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45maiyGTlmc0U1cMeta } from "D:/Vue/ps15one-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93ZtXFJvjjcfMeta } from "D:/Vue/ps15one-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinogy9gX7cmfKMeta } from "D:/Vue/ps15one-web/pages/livecasino.vue?macro=true";
import { default as reset_45password5ueOTT5880Meta } from "D:/Vue/ps15one-web/pages/reset-password.vue?macro=true";
import { default as _91id_938QrOfVqC09Meta } from "D:/Vue/ps15one-web/pages/the-thao/[id].vue?macro=true";
import { default as indexNbxCGNq5ChMeta } from "D:/Vue/ps15one-web/pages/the-thao/index.vue?macro=true";
import { default as indexKsHyR50D77Meta } from "D:/Vue/ps15one-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93ECPS7QTt9kMeta } from "D:/Vue/ps15one-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexwnh2LhQRWrMeta } from "D:/Vue/ps15one-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93xSHzvNQBigMeta } from "D:/Vue/ps15one-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tuc6ZoNdjzzHxMeta } from "D:/Vue/ps15one-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailCeTFPg6e9kMeta } from "D:/Vue/ps15one-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountXb9Q0qEyE6Meta?.name ?? "account",
    path: accountXb9Q0qEyE6Meta?.path ?? "/account",
    children: [
  {
    name: bankedGDWmxW1xMeta?.name ?? "account-bank",
    path: bankedGDWmxW1xMeta?.path ?? "bank",
    meta: bankedGDWmxW1xMeta || {},
    alias: bankedGDWmxW1xMeta?.alias || [],
    redirect: bankedGDWmxW1xMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bankBpUIC7NqqWMeta?.name ?? "account-deposit-bank",
    path: bankBpUIC7NqqWMeta?.path ?? "deposit/bank",
    meta: bankBpUIC7NqqWMeta || {},
    alias: bankBpUIC7NqqWMeta?.alias || [],
    redirect: bankBpUIC7NqqWMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardwSCPmIlML6Meta?.name ?? "account-deposit-card",
    path: cardwSCPmIlML6Meta?.path ?? "deposit/card",
    meta: cardwSCPmIlML6Meta || {},
    alias: cardwSCPmIlML6Meta?.alias || [],
    redirect: cardwSCPmIlML6Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepay8CYNmbInJSMeta?.name ?? "account-deposit-codepay",
    path: codepay8CYNmbInJSMeta?.path ?? "deposit/codepay",
    meta: codepay8CYNmbInJSMeta || {},
    alias: codepay8CYNmbInJSMeta?.alias || [],
    redirect: codepay8CYNmbInJSMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2tXdOeqYFwFMeta?.name ?? "account-deposit-codepay2",
    path: codepay2tXdOeqYFwFMeta?.path ?? "deposit/codepay2",
    meta: codepay2tXdOeqYFwFMeta || {},
    alias: codepay2tXdOeqYFwFMeta?.alias || [],
    redirect: codepay2tXdOeqYFwFMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptoT22mli5di7Meta?.name ?? "account-deposit-crypto",
    path: cryptoT22mli5di7Meta?.path ?? "deposit/crypto",
    meta: cryptoT22mli5di7Meta || {},
    alias: cryptoT22mli5di7Meta?.alias || [],
    redirect: cryptoT22mli5di7Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: momor7GSUlCxGbMeta?.name ?? "account-deposit-momo",
    path: momor7GSUlCxGbMeta?.path ?? "deposit/momo",
    meta: momor7GSUlCxGbMeta || {},
    alias: momor7GSUlCxGbMeta?.alias || [],
    redirect: momor7GSUlCxGbMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/deposit/momo.vue").then(m => m.default || m)
  },
  {
    name: historyHZ7SU0RRZvMeta?.name ?? "account-history",
    path: historyHZ7SU0RRZvMeta?.path ?? "history",
    children: [
  {
    name: betkU6xKVBQldMeta?.name ?? "account-history-bet",
    path: betkU6xKVBQldMeta?.path ?? "bet",
    meta: betkU6xKVBQldMeta || {},
    alias: betkU6xKVBQldMeta?.alias || [],
    redirect: betkU6xKVBQldMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionKev395Q6NcMeta?.name ?? "account-history-transaction",
    path: transactionKev395Q6NcMeta?.path ?? "transaction",
    meta: transactionKev395Q6NcMeta || {},
    alias: transactionKev395Q6NcMeta?.alias || [],
    redirect: transactionKev395Q6NcMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyHZ7SU0RRZvMeta || {},
    alias: historyHZ7SU0RRZvMeta?.alias || [],
    redirect: historyHZ7SU0RRZvMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileQ4iRJGOUA0Meta?.name ?? "account-profile",
    path: profileQ4iRJGOUA0Meta?.path ?? "profile",
    meta: profileQ4iRJGOUA0Meta || {},
    alias: profileQ4iRJGOUA0Meta?.alias || [],
    redirect: profileQ4iRJGOUA0Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: bankUAOsx0DNKLMeta?.name ?? "account-withdraw-bank",
    path: bankUAOsx0DNKLMeta?.path ?? "withdraw/bank",
    meta: bankUAOsx0DNKLMeta || {},
    alias: bankUAOsx0DNKLMeta?.alias || [],
    redirect: bankUAOsx0DNKLMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: card3ZKifv2DkFMeta?.name ?? "account-withdraw-card",
    path: card3ZKifv2DkFMeta?.path ?? "withdraw/card",
    meta: card3ZKifv2DkFMeta || {},
    alias: card3ZKifv2DkFMeta?.alias || [],
    redirect: card3ZKifv2DkFMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoAdyaHGWp6cMeta?.name ?? "account-withdraw-crypto",
    path: cryptoAdyaHGWp6cMeta?.path ?? "withdraw/crypto",
    meta: cryptoAdyaHGWp6cMeta || {},
    alias: cryptoAdyaHGWp6cMeta?.alias || [],
    redirect: cryptoAdyaHGWp6cMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountXb9Q0qEyE6Meta || {},
    alias: accountXb9Q0qEyE6Meta?.alias || [],
    redirect: accountXb9Q0qEyE6Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: cau_45hoi_45thuong_45gaph4vZlmfIzWMeta?.name ?? "cau-hoi-thuong-gap",
    path: cau_45hoi_45thuong_45gaph4vZlmfIzWMeta?.path ?? "/cau-hoi-thuong-gap",
    children: [
  {
    name: _91alias_93iLjCmMdub6Meta?.name ?? "cau-hoi-thuong-gap-alias",
    path: _91alias_93iLjCmMdub6Meta?.path ?? ":alias",
    meta: _91alias_93iLjCmMdub6Meta || {},
    alias: _91alias_93iLjCmMdub6Meta?.alias || [],
    redirect: _91alias_93iLjCmMdub6Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/cau-hoi-thuong-gap/[alias].vue").then(m => m.default || m)
  }
],
    meta: cau_45hoi_45thuong_45gaph4vZlmfIzWMeta || {},
    alias: cau_45hoi_45thuong_45gaph4vZlmfIzWMeta?.alias || [],
    redirect: cau_45hoi_45thuong_45gaph4vZlmfIzWMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/cau-hoi-thuong-gap.vue").then(m => m.default || m)
  },
  {
    name: base_45buttontISnuJm7WtMeta?.name ?? "dev-base-button",
    path: base_45buttontISnuJm7WtMeta?.path ?? "/dev/base-button",
    meta: base_45buttontISnuJm7WtMeta || {},
    alias: base_45buttontISnuJm7WtMeta?.alias || [],
    redirect: base_45buttontISnuJm7WtMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downPNFpZN7bI6Meta?.name ?? "dev-base-count-down",
    path: base_45count_45downPNFpZN7bI6Meta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downPNFpZN7bI6Meta || {},
    alias: base_45count_45downPNFpZN7bI6Meta?.alias || [],
    redirect: base_45count_45downPNFpZN7bI6Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiokhGSgKimWcMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiokhGSgKimWcMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiokhGSgKimWcMeta || {},
    alias: base_45dropdown_45radiokhGSgKimWcMeta?.alias || [],
    redirect: base_45dropdown_45radiokhGSgKimWcMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgCeLZVrOXvFMeta?.name ?? "dev-base-img",
    path: base_45imgCeLZVrOXvFMeta?.path ?? "/dev/base-img",
    meta: base_45imgCeLZVrOXvFMeta || {},
    alias: base_45imgCeLZVrOXvFMeta?.alias || [],
    redirect: base_45imgCeLZVrOXvFMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputVUY24jUGX1Meta?.name ?? "dev-base-input",
    path: base_45inputVUY24jUGX1Meta?.path ?? "/dev/base-input",
    meta: base_45inputVUY24jUGX1Meta || {},
    alias: base_45inputVUY24jUGX1Meta?.alias || [],
    redirect: base_45inputVUY24jUGX1Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingB4rbUpSqeaMeta?.name ?? "dev-base-loading",
    path: base_45loadingB4rbUpSqeaMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingB4rbUpSqeaMeta || {},
    alias: base_45loadingB4rbUpSqeaMeta?.alias || [],
    redirect: base_45loadingB4rbUpSqeaMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalPsEFkK72POMeta?.name ?? "dev-base-modal",
    path: base_45modalPsEFkK72POMeta?.path ?? "/dev/base-modal",
    meta: base_45modalPsEFkK72POMeta || {},
    alias: base_45modalPsEFkK72POMeta?.alias || [],
    redirect: base_45modalPsEFkK72POMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputwcihpHKBx9Meta?.name ?? "dev-base-money-input",
    path: base_45money_45inputwcihpHKBx9Meta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputwcihpHKBx9Meta || {},
    alias: base_45money_45inputwcihpHKBx9Meta?.alias || [],
    redirect: base_45money_45inputwcihpHKBx9Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationBclfZx6TaHMeta?.name ?? "dev-base-pagination",
    path: base_45paginationBclfZx6TaHMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationBclfZx6TaHMeta || {},
    alias: base_45paginationBclfZx6TaHMeta?.alias || [],
    redirect: base_45paginationBclfZx6TaHMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45banky1OlZ8s7PpMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45banky1OlZ8s7PpMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45banky1OlZ8s7PpMeta || {},
    alias: base_45select_45banky1OlZ8s7PpMeta?.alias || [],
    redirect: base_45select_45banky1OlZ8s7PpMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemyYepjAmzzkMeta?.name ?? "dev-game-item",
    path: game_45itemyYepjAmzzkMeta?.path ?? "/dev/game-item",
    meta: game_45itemyYepjAmzzkMeta || {},
    alias: game_45itemyYepjAmzzkMeta?.alias || [],
    redirect: game_45itemyYepjAmzzkMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexxt7GbJsKldMeta?.name ?? "dev",
    path: indexxt7GbJsKldMeta?.path ?? "/dev",
    meta: indexxt7GbJsKldMeta || {},
    alias: indexxt7GbJsKldMeta?.alias || [],
    redirect: indexxt7GbJsKldMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameVjTWUxCFpsMeta?.name ?? "game",
    path: gameVjTWUxCFpsMeta?.path ?? "/game",
    children: [
  {
    name: _91type_939QdrAfYNe1Meta?.name ?? "game-type",
    path: _91type_939QdrAfYNe1Meta?.path ?? ":type",
    meta: _91type_939QdrAfYNe1Meta || {},
    alias: _91type_939QdrAfYNe1Meta?.alias || [],
    redirect: _91type_939QdrAfYNe1Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameVjTWUxCFpsMeta || {},
    alias: gameVjTWUxCFpsMeta?.alias || [],
    redirect: gameVjTWUxCFpsMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieu5wVh74jK2KMeta?.name ?? "gioi-thieu",
    path: gioi_45thieu5wVh74jK2KMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93DdJI3r7bGkMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93DdJI3r7bGkMeta?.path ?? ":alias",
    meta: _91alias_93DdJI3r7bGkMeta || {},
    alias: _91alias_93DdJI3r7bGkMeta?.alias || [],
    redirect: _91alias_93DdJI3r7bGkMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieu5wVh74jK2KMeta || {},
    alias: gioi_45thieu5wVh74jK2KMeta?.alias || [],
    redirect: gioi_45thieu5wVh74jK2KMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dandk6vPvminRMeta?.name ?? "huong-dan",
    path: huong_45dandk6vPvminRMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93CcHuuyeHOTMeta?.name ?? "huong-dan-alias",
    path: _91alias_93CcHuuyeHOTMeta?.path ?? ":alias",
    meta: _91alias_93CcHuuyeHOTMeta || {},
    alias: _91alias_93CcHuuyeHOTMeta?.alias || [],
    redirect: _91alias_93CcHuuyeHOTMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dandk6vPvminRMeta || {},
    alias: huong_45dandk6vPvminRMeta?.alias || [],
    redirect: huong_45dandk6vPvminRMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexEBVzRFFC3yMeta?.name ?? "index",
    path: indexEBVzRFFC3yMeta?.path ?? "/",
    meta: indexEBVzRFFC3yMeta || {},
    alias: indexEBVzRFFC3yMeta?.alias || [],
    redirect: indexEBVzRFFC3yMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45maiyGTlmc0U1cMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93WCk3ZNxxNXMeta?.name ?? "khuyen-mai-id",
    path: _91id_93WCk3ZNxxNXMeta?.path ?? ":id",
    meta: _91id_93WCk3ZNxxNXMeta || {},
    alias: _91id_93WCk3ZNxxNXMeta?.alias || [],
    redirect: _91id_93WCk3ZNxxNXMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexu2smPBGRiVMeta?.name ?? "khuyen-mai",
    path: indexu2smPBGRiVMeta?.path ?? "",
    meta: indexu2smPBGRiVMeta || {},
    alias: indexu2smPBGRiVMeta?.alias || [],
    redirect: indexu2smPBGRiVMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IMISfzzAWIMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93IMISfzzAWIMeta?.path ?? "the-loai/:id",
    meta: _91id_93IMISfzzAWIMeta || {},
    alias: _91id_93IMISfzzAWIMeta?.alias || [],
    redirect: _91id_93IMISfzzAWIMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipv1C2GuEiSdMeta?.name ?? "khuyen-mai-vip",
    path: vipv1C2GuEiSdMeta?.path ?? "vip",
    meta: vipv1C2GuEiSdMeta || {},
    alias: vipv1C2GuEiSdMeta?.alias || [],
    redirect: vipv1C2GuEiSdMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45maiyGTlmc0U1cMeta?.name ?? undefined,
    meta: khuyen_45maiyGTlmc0U1cMeta || {},
    alias: khuyen_45maiyGTlmc0U1cMeta?.alias || [],
    redirect: khuyen_45maiyGTlmc0U1cMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinogy9gX7cmfKMeta?.name ?? "livecasino",
    path: livecasinogy9gX7cmfKMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93ZtXFJvjjcfMeta?.name ?? "livecasino-type",
    path: _91type_93ZtXFJvjjcfMeta?.path ?? ":type",
    meta: _91type_93ZtXFJvjjcfMeta || {},
    alias: _91type_93ZtXFJvjjcfMeta?.alias || [],
    redirect: _91type_93ZtXFJvjjcfMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinogy9gX7cmfKMeta || {},
    alias: livecasinogy9gX7cmfKMeta?.alias || [],
    redirect: livecasinogy9gX7cmfKMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45password5ueOTT5880Meta?.name ?? "reset-password",
    path: reset_45password5ueOTT5880Meta?.path ?? "/reset-password",
    meta: reset_45password5ueOTT5880Meta || {},
    alias: reset_45password5ueOTT5880Meta?.alias || [],
    redirect: reset_45password5ueOTT5880Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_938QrOfVqC09Meta?.name ?? "the-thao-id",
    path: _91id_938QrOfVqC09Meta?.path ?? "/the-thao/:id",
    meta: _91id_938QrOfVqC09Meta || {},
    alias: _91id_938QrOfVqC09Meta?.alias || [],
    redirect: _91id_938QrOfVqC09Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNbxCGNq5ChMeta?.name ?? "the-thao",
    path: indexNbxCGNq5ChMeta?.path ?? "/the-thao",
    meta: indexNbxCGNq5ChMeta || {},
    alias: indexNbxCGNq5ChMeta?.alias || [],
    redirect: indexNbxCGNq5ChMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexKsHyR50D77Meta?.name ?? "the-thao-lich-thi-dau",
    path: indexKsHyR50D77Meta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexKsHyR50D77Meta || {},
    alias: indexKsHyR50D77Meta?.alias || [],
    redirect: indexKsHyR50D77Meta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tuc6ZoNdjzzHxMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93ECPS7QTt9kMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93ECPS7QTt9kMeta?.path ?? ":alias",
    meta: _91alias_93ECPS7QTt9kMeta || {},
    alias: _91alias_93ECPS7QTt9kMeta?.alias || [],
    redirect: _91alias_93ECPS7QTt9kMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexwnh2LhQRWrMeta?.name ?? "tin-tuc",
    path: indexwnh2LhQRWrMeta?.path ?? "",
    meta: indexwnh2LhQRWrMeta || {},
    alias: indexwnh2LhQRWrMeta?.alias || [],
    redirect: indexwnh2LhQRWrMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93xSHzvNQBigMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93xSHzvNQBigMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93xSHzvNQBigMeta || {},
    alias: _91Slug_93xSHzvNQBigMeta?.alias || [],
    redirect: _91Slug_93xSHzvNQBigMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tuc6ZoNdjzzHxMeta?.name ?? undefined,
    meta: tin_45tuc6ZoNdjzzHxMeta || {},
    alias: tin_45tuc6ZoNdjzzHxMeta?.alias || [],
    redirect: tin_45tuc6ZoNdjzzHxMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailCeTFPg6e9kMeta?.name ?? "verify-mail",
    path: verify_45mailCeTFPg6e9kMeta?.path ?? "/verify-mail",
    meta: verify_45mailCeTFPg6e9kMeta || {},
    alias: verify_45mailCeTFPg6e9kMeta?.alias || [],
    redirect: verify_45mailCeTFPg6e9kMeta?.redirect || undefined,
    component: () => import("D:/Vue/ps15one-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]