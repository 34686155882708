import { useCookie } from '#app'

export default defineNuxtPlugin(() => {
  const device = useDevice()
  const layout = useCookie('layout')

  // Reset layout cookie if not on Apple/Safari
  if (!device.isApple || !device.isSafari) {
    layout.value = null
  }

  // Handle mobile or tablet layout
  if (layout.value === 'mobile' || device.isMobileOrTablet) {
    device.isMobile = true
    device.isMobileOrTablet = true
    return
  }

  // Client-side detection
  if (process.client) {
    const isTouchDevice = navigator?.maxTouchPoints > 0
    if ((isTouchDevice && device.isApple) || device.isMobileOrTablet) {
      device.isMobile = true
      device.isMobileOrTablet = true
      layout.value = 'mobile'
      window.location.reload()
    }
  }
})
